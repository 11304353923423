<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-overlay
          :show="loader"
          rounded="sm"
          variant="dark"
          :opacity="0.1"
        >
          <b-card title="Update Multi Device Discount">
            <validation-observer ref="updateMultiDeviceDiscountValidator">
              <b-form @submit.prevent="onSubmit">
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label="Discount"
                      label-for="discount"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="discount"
                        rules="required"
                      >
                        <div>
                          <b-form-input
                            v-model="form.discount"
                            name="discount"
                            placeholder="Enter discount"
                          />
                        </div>
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Number of devices"
                      label-for="devices"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="devices"
                        rules="required"
                      >
                        <div>
                          <b-form-input
                            v-model="form.no_of_devices"
                            name="devices"
                            placeholder="Enter number of devices"
                          />
                        </div>
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Status"
                      label-for="status"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="status"
                        rules="required"
                      >
                        <b-form-select
                          v-model="form.status"
                          :options="options"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <div class="d-flex justify-content-end">
                      <b-button
                        variant="primary"
                        type="submit"
                      >
                        Update
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>
<script>

import { createNamespacedHelpers } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const discountsModule = createNamespacedHelpers('discounts')

export default {
  data() {
    return {
      loader: false,
      form: {
        discount: '',
        no_of_devices: '',
        status: null,
      },
      options: [
        { value: null, text: 'Please select status', disabled: true },
        { value: 1, text: 'Enable' },
        { value: 0, text: 'Disable' },
      ],
      currentData: null,
    }
  },
  async mounted() {
    this.loader = true
    await this.fetchData()
    this.loader = false
  },
  methods: {
    ...discountsModule.mapActions(['FETCH_SINGLE_MULTI_DEVICE', 'UPDATE_MULTI_DEVICE']),

    async fetchData() {
      try {
        this.loader = true
        this.currentData = await this.FETCH_SINGLE_MULTI_DEVICE(this.$route.params.id)
        this.form.discount = this.currentData.discount
        this.form.status = this.currentData.status
        this.form.no_of_devices = this.currentData.no_of_devices
        this.loader = false
      } catch (error) {
        this.loader = false
      }
    },

    onSubmit() {
      this.$refs.updateMultiDeviceDiscountValidator.validate().then(async success => {
        if (success) {
          try {
            this.loader = true
            const resp = await this.UPDATE_MULTI_DEVICE({ id: this.$route.params.id, data: this.form })
            if (resp) {
              this.$nextTick(() => {
                this.$refs.updateMultiDeviceDiscountValidator.reset()
              })
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Multi device discount Updated!',
                  icon: 'checkIcon',
                  variant: 'success',
                  text: 'Multi device discount has been updated successfully!',
                },
              })
            }
            this.loader = false
          } catch (error) {
            this.loader = false
          }
        }
      })
    },
  },
}
</script>
